import React from "react";

import { Score, SubmitScoresData } from "../../../interfaces/Scores";
import { BaseTable } from "./BaseTable";

export interface ScoresTableProps {
  data?: SubmitScoresData;
  unsavedScores?: Score[];
}

export const ScoresTable: React.FC<ScoresTableProps> = ({
  data,
  unsavedScores,
}) => {
  if (!data?.scores.length || !unsavedScores) return <>Loading...</>;

  const renderRow = (score: Score) => (
    <tr
      key={`${score.school}-${score.class}-${score.showDate}-${score.score}-${score.showUrl}`}
    >
      <td>{score.school}</td>
      <td>{score.score}</td>
      <td>{score.class}</td>
      <td>{score.showDate}</td>
      <td>
        {score.showUrl ? (
          <a href={score.showUrl} target="_blank" rel="noopener noreferrer">
            {score.showName}
          </a>
        ) : (
          score.showName
        )}
      </td>
    </tr>
  );
  const tableHeader = (
    <tr>
      <th>Unit</th>
      <th>Score</th>
      <th>Class</th>
      <th>Date</th>
      <th>Show</th>
    </tr>
  );

  return (
    <div>
      <div>
        <strong>Year:</strong> {data.year}
      </div>

      <BaseTable<Score>
        header={`Unsaved: ${unsavedScores.length}`}
        data={unsavedScores}
        tableHeader={tableHeader}
        renderRow={renderRow}
      />

      <BaseTable<Score>
        header={`All scores: ${data.scores.length}`}
        data={data.scores}
        tableHeader={tableHeader}
        renderRow={renderRow}
      />
    </div>
  );
};

export default ScoresTable;
