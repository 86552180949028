import Axios, { AxiosError } from "axios";
import React, { ChangeEvent, FC, FormEvent, useEffect, useState } from "react";

import { Form as FormType } from "../../../interfaces/Form";
import {
  ScrapeGroupsResponse,
  SubmitGroupsData,
} from "../../../interfaces/Groups";
import {
  ScrapeScoresResponse,
  SubmitScoresData,
} from "../../../interfaces/Scores";
import { ScrapeData } from "../../../interfaces/Scrape";

export interface FormProps {
  form: FormType;
  submitData: SubmitGroupsData | SubmitScoresData | undefined;
  setSubmitData:
    | ((data?: SubmitGroupsData) => void)
    | ((data?: SubmitScoresData) => void);
}

const Form: FC<FormProps> = ({
  form: { defaultValue, name, label, scrapeUrl, submitUrl },
  submitData,
  setSubmitData,
}) => {
  const [value, setValue] = useState(defaultValue);
  const [scraping, setScraping] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);

  const resetState = () => {
    setSuccess(null);
    setError(null);
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setValue(value);
  };

  const onGet = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setScraping(true);
    resetState();
    setSubmitData(undefined);

    try {
      const { data } = await Axios.get<
        ScrapeData,
        ScrapeGroupsResponse | ScrapeScoresResponse
      >(scrapeUrl, {
        params: { url: value },
      });
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore figure out how to consolidate these types
      setSubmitData(data);
      setSuccess(`${data.success}`);
    } catch (e) {
      setError(
        `${(e as AxiosError).response?.data || (e as AxiosError).message}`
      );
    } finally {
      setScraping(false);
    }
  };

  const onSubmit = async () => {
    setSubmitting(true);
    resetState();

    try {
      const { data } = await Axios.post(submitUrl, submitData);
      setSubmitData(undefined);
      setSuccess(`${data.success}`);
      setSubmitData(undefined);
    } catch (e) {
      setError(`${e}`);
    } finally {
      setSubmitting(false);
    }
  };

  const submitDisabled = submitting || !submitData?.hasOwnProperty(name);

  useEffect(() => {
    onGet({ preventDefault: () => undefined } as FormEvent<HTMLFormElement>);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="card mb-3 p-3 rounded">
      <form onSubmit={onGet} className="card-block">
        <h4 className="card-title">{label}</h4>
        <div className="form-group row">
          <div className="col-md-6">
            <input
              className="form-control mb-2"
              type="text"
              value={value}
              onChange={onChange}
            />
          </div>
          <div className="col-md-3 mb-2">
            <button
              className="btn btn-secondary btn-block"
              type="submit"
              disabled={scraping}
            >
              Get {label}
            </button>
          </div>
          <div className="col-md-3 mb-2">
            <button
              className="btn btn-success btn-block"
              disabled={submitDisabled}
              type="button"
              onClick={onSubmit}
            >
              Submit {label}
            </button>
          </div>
        </div>
      </form>
      {success && <div className="alert-success p-2">{success}</div>}
      {error && <div className="alert-danger p-2">{error}</div>}
    </div>
  );
};
export default Form;
