import { Group } from "../../../interfaces/Groups";
import { Score } from "../../../interfaces/Scores";

export interface BaseTableProps<T extends Group | Score> {
  data: T[];
  renderRow: (item: T) => JSX.Element;
  tableHeader: JSX.Element;
  header: string;
}
export const BaseTable = <T extends Group | Score>({
  data,
  tableHeader,
  renderRow,
  header,
}: BaseTableProps<T>) => {
  return (
    <div className="pt-3">
      <strong>{header}</strong>
      <table className="table table-striped">
        <thead>{tableHeader}</thead>
        <tbody>{data.map(renderRow)}</tbody>
      </table>
    </div>
  );
};
