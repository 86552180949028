import { FC } from "react";
import { FaInfoCircle } from "react-icons/fa";
import { getDiff, formatShow, formatDate } from "../helpers";
import { Class } from "../interfaces/Class";
import { Score } from "../interfaces/Scores";
import { NoScores } from "./NoScores";

export interface VerticalClassProps {
  scores: Score[];
  name: string;
  classObj: Class;
}
export const VerticalClass: FC<VerticalClassProps> = ({
  scores,
  name,
  classObj,
}) => {
  return scores ? (
    <div className="d-block">
      <div
        className="pt-5 px-3 score border-top border-bottom"
        id={classObj.shortName.toLowerCase()}
      >
        <h4 className="font-weight-bold mb-4">{name}</h4>
      </div>

      {scores.length ? (
        <ul className="scores px-3">
          {scores.map((score, i) => (
            <li
              className="score"
              style={{
                marginTop: `${Math.min(getDiff(classObj, i), 8) * 1.25 + 1}rem`,
              }}
              key={score.school}
            >
              <div className="score-rank">{i + 1}</div>
              <div className="score-info">
                <div className="score-unit">
                  {score.url ? (
                    <a
                      href={score.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {score.school}
                    </a>
                  ) : (
                    <span>{score.school}</span>
                  )}{" "}
                  {score.location && (
                    <span className="d-none d-md-inline">
                      ({score.location})
                    </span>
                  )}{" "}
                  -
                </div>
                <div className="score-score">{score.score}</div>
                {i ? (
                  <div className="diff pr-2 d-none d-sm-inline">
                    (-{getDiff(classObj, i)})
                  </div>
                ) : null}
                <div className="d-none d-sm-inline">
                  -{" "}
                  {score.showUrl ? (
                    <a
                      href={score.showUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {formatShow(score.showName)}
                    </a>
                  ) : (
                    <span>{formatShow(score.showName)}</span>
                  )}
                </div>
                <div className="score-date">
                  {" "}
                  ({formatDate(score.showDate)})
                </div>
                {score.inChampionships === null && (
                  <div
                    className="score-info-icon"
                    title={score.inChampionships ? "" : "Not going to WGI"}
                  >
                    <FaInfoCircle />
                  </div>
                )}
              </div>
            </li>
          ))}
        </ul>
      ) : (
        <NoScores className="mt-4" />
      )}
    </div>
  ) : null;
};
