import React, { useCallback, useEffect, useState } from "react";

const REFERENCE_ID = "header";

function goToTop() {
  const el = document.getElementById(REFERENCE_ID);
  window.scrollTo(0, el?.offsetTop ? el?.offsetTop - 32 : 0);
  document.body.className = "";
}

export const ScrollToTop: React.FC = () => {
  const [showGoToTop, setShowGoToTop] = useState(false);

  const scrollHandler = useCallback(() => {
    const el = document.getElementById(REFERENCE_ID);

    if (!el) return;

    const location = el.getBoundingClientRect().top;

    if (location <= 0 && showGoToTop === false) {
      setShowGoToTop(true);
      document.body.className = "scrolled";
    } else if (window.scrollY <= 0 && showGoToTop === true) {
      setShowGoToTop(false);
      document.body.className = "";
    }
  }, [showGoToTop]);

  useEffect(() => {
    window.addEventListener("scroll", scrollHandler);

    return () => {
      window.removeEventListener("scroll", scrollHandler);
    };
  }, [showGoToTop, scrollHandler]);

  return (
    <button
      className={`scroll-to-top${showGoToTop ? " visible" : ""}`}
      onClick={goToTop}
    >
      Go to top
    </button>
  );
};

export default ScrollToTop;
