import { FC } from "react";
import { FaInfoCircle } from "react-icons/fa";
import { getDiff, formatDate, formatShow } from "../helpers";
import { Score } from "../interfaces/Scores";
import { Class } from "../interfaces/Class";
import { NoScores } from "./NoScores";

export interface TableClassProps {
  scores: Score[];
  name: string;
  classObj: Class;
}

const columnClasses = [
  "col-2 col-sm-2 col-md-2 col-lg-1", // Rank (XS)
  "col-6 col-sm-5 col-md-4 col-lg-3", // Ensemble (XS)
  "col-0 col-sm-0 col-md-0 col-lg-2 d-lg-inline-block d-none", // HomeTown (LG)
  "col-4 col-sm-3 col-md-2 col-lg-2", // Score (XS)
  "col-0 col-sm-2 col-md-2 col-lg-1 d-sm-inline-block d-none", // Date (XS)
  "col-0 col-sm-0 col-md-2 col-lg-3 d-md-inline-block d-none", // Regional (MD)
];
const headerClass = "border-bottom-0";

export const TableClass: FC<TableClassProps> = ({ scores, name, classObj }) => {
  return scores ? (
    <div>
      <div className="pt-5 score" id={classObj.shortName.toLowerCase()}>
        <h4 className="font-weight-bold mb-4 px-3">{name}</h4>
      </div>

      {scores.length ? (
        <table className="table table-striped">
          <thead className="border-bottom-0">
            <tr className="d-flex">
              <th className={`${columnClasses[0]} ${headerClass}`}>Rank</th>
              <th className={`${columnClasses[1]} ${headerClass}`}>Ensemble</th>
              <th className={`${columnClasses[2]} ${headerClass}`}>Hometown</th>
              <th className={`${columnClasses[3]} ${headerClass}`}>Score</th>
              <th className={`${columnClasses[4]} ${headerClass}`}>Date</th>
              <th className={`${columnClasses[5]} ${headerClass}`}>Regional</th>
            </tr>
          </thead>
          <tbody>
            {scores.map((score, i) => (
              <tr key={score.school} className="results-row d-flex">
                <td className={`${columnClasses[0]}`}>{i + 1}</td>
                <td className={`${columnClasses[1]}`}>
                  <div className="d-flex align-items-center">
                    {score.url ? (
                      <a
                        href={score.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ marginRight: "0.5rem" }}
                      >
                        {score.school}
                      </a>
                    ) : (
                      <div style={{ marginRight: "0.5rem" }}>
                        {score.school}
                      </div>
                    )}
                    <div
                      style={{ marginTop: "4px" }}
                      title={score.inChampionships ? "" : "Not going to WGI"}
                    >
                      {score.inChampionships === null && <FaInfoCircle />}
                    </div>
                  </div>
                </td>
                <td className={`${columnClasses[2]}`}>{score.location}</td>
                <td className={`${columnClasses[3]}`}>
                  <div className="d-flex align-items-center">
                    <div>{score.score}</div>
                    {i ? (
                      <div className="diff pl-2">(-{getDiff(classObj, i)})</div>
                    ) : null}
                  </div>
                </td>
                <td className={`${columnClasses[4]}`}>
                  {formatDate(score.showDate)}
                </td>
                <td className={`${columnClasses[5]}`}>
                  {score.showUrl ? (
                    <a
                      href={score.showUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {formatShow(score.showName)}
                    </a>
                  ) : (
                    <div>{formatShow(score.showName)}</div>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <NoScores className="border-top border-bottom py-4" />
      )}
    </div>
  ) : null;
};
