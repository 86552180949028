import React, { useEffect, useState } from "react";
import cx from "classnames";

import Form from "./components/Form";
import GroupsTable from "./components/GroupsTable";
import ScoresTable from "./components/ScoresTable";

import { Form as FormType } from "../../interfaces/Form";
import {
  GetGroupsResponse,
  Group,
  SubmitGroupsData,
} from "../../interfaces/Groups";
import {
  GetScoresResponse,
  Score,
  SubmitScoresData,
} from "../../interfaces/Scores";
import { fetchGroups, fetchScores, rewriteUrl } from "../../helpers";

const getGroupKey = (group: Group) => `${group.name}-${group.class}`;
const getScoreKey = (score: Score) => {
  const sanitizedSchool = score.school.replace(/\([^)]+\)/g, "").trim();
  return `${sanitizedSchool}-${score.score}-${score.showName}`;
};

const script = `
(async function run() {
  const scores = [];
  const showNameHash = {};

  const getPercEvents = () => {
    const events = document.querySelectorAll(".event");
    return Array.from(events);
  };

  const getNextPercEvent = async () => {
    const events = getPercEvents();
    for (const event of events) {
      const showName = event
        .querySelector("div")
        .innerText.replace("2024 WGI ", "");
      if (showName in showNameHash) {
        continue;
      }

      showNameHash[showName] = true;

      event.click();
      await new Promise((resolve) => setTimeout(resolve, 500));

      const showDate = document
        .getElementById("cs-org-scores-subheader")
        .innerText.replace(/\\s.*/, "");
      const classSections = document.querySelectorAll(".roundArea");

      Array.from(classSections).forEach((section) => {
        const className = section
          .querySelector(".roundName")
          .innerText.replace(/\\n.*/, "")
          .replace(" View Recap", "")
          .replace("Scholastic World", "Scholastic Marching World")
          .replace("Scholastic Open", "Scholastic Marching Open")
          .replace("Scholastic A", "Scholastic Marching A")
          .replace("Independent World", "Independent Marching World")
          .replace("Independent Open", "Independent Marching Open")
          .replace("Independent A", "Independent Marching A");

        if (className.includes("Percussion")) {
          console.log("Adding show/class", showName, className);
          const showUrlEl = section.querySelector(".roundName a");
          const showUrl = showUrlEl
            ? showUrlEl.getAttribute("href")
            : undefined;
          const scoreRows = section.querySelectorAll(".scoreRow");

          Array.from(scoreRows).forEach((row) => {
            const school = row.querySelector(".group").innerText;
            const score = row.querySelector(".score").innerText;
            const scoreObj = {
              showName,
              showDate,
              class: className.replace("Percussion", "").trim(),
              school,
              score,
              showUrl,
            };
            scores.push(scoreObj);
          });
        } else {
          console.log("Skipping class", className);
        }
      });
      const backLink = document.querySelector(
        "#cs-org-scores-menu-viewEvents a"
      );
      backLink.click();
      await new Promise((resolve) => setTimeout(resolve, 500));
      await getNextPercEvent();
      break;
    }
  };

  await getNextPercEvent();
  console.log(JSON.stringify(scores));
})();
`;

export const Update: React.FC = () => {
  const [groupData, setGroupData] = useState<SubmitGroupsData | undefined>();
  const [scoreData, setScoreData] = useState<SubmitScoresData | undefined>();
  const [currentTab, setCurrentTab] = useState<"groups" | "scores">("groups");
  const [existingScores, setExistingScores] =
    useState<GetScoresResponse["classes"]>();
  const [existingGroups, setExistingGroups] =
    useState<GetGroupsResponse["groups"]>();

  const year = new Date().getFullYear().toString();
  const fetchAndSetExistingScores = async () => {
    const data = await fetchScores(year);
    setExistingScores(data.classes);
  };
  const fetchAndSetExistingGroups = async () => {
    const data = await fetchGroups(year);
    setExistingGroups(data.groups);
  };

  const existingGroupsHash = Object.values(existingGroups || []).reduce(
    (acc, cur) => {
      const key = getGroupKey(cur);
      acc[key] = true;
      return acc;
    },
    {} as Record<string, boolean>
  );

  const unsavedGroups = groupData?.groups.filter(
    (group) => !(getGroupKey(group) in existingGroupsHash)
  );

  const existingScoresHash = Object.values(existingScores || []).reduce(
    (acc, cur) => {
      cur.scores.forEach((score) => {
        const key = getScoreKey(score);
        acc[key] = true;
      });

      return acc;
    },
    {} as Record<string, boolean>
  );

  const unsavedScores = scoreData?.scores.filter((score) => {
    const key = getScoreKey(score);
    return !(key in existingScoresHash);
  });

  const FORMS: FormType[] = [
    {
      label: "Groups",
      name: "groups",
      defaultValue: "https://www.wgi.org/percussion/p-current-entries/",
      scrapeUrl: rewriteUrl("/api/scrapeGroups.php"),
      submitUrl: rewriteUrl("/api/submitGroups.php"),
      setSubmitData: setGroupData,
      submitData: {
        groups: groupData?.groups || [],
        count: groupData?.groups.length ?? 0,
        year,
        debug: [],
      },
    },
    {
      label: "Scores",
      name: "scores",
      defaultValue: `https://wgi.org/scores`,
      scrapeUrl: rewriteUrl("/api/scrapeScores.php"),
      submitUrl: rewriteUrl("/api/submitScores.php"),
      setSubmitData: setScoreData,
      submitData: {
        scores: unsavedScores || [],
        count: unsavedScores?.length ?? 0,
        year,
        debug: [],
      },
    },
  ];

  useEffect(() => {
    fetchAndSetExistingGroups();
    fetchAndSetExistingScores();
  }, []);

  return (
    <div className="container my-3">
      <h2>Update</h2>
      <a href="/">Back</a>
      {FORMS.map((form) => (
        <Form
          form={form}
          key={form.name}
          setSubmitData={form.setSubmitData}
          submitData={form.submitData}
        />
      ))}
      <div className="border p-4 mb-2">
        <h4>Scores Scraping Snippet</h4>
        <p>
          <a href="https://wgi.org/scores" target="_blank" rel="noreferrer">
            https://wgi.org/scores
          </a>
        </p>
        <p>
          <button
            className="btn btn-primary"
            onClick={async () => {
              try {
                navigator.clipboard.writeText(script);
                alert("Copied!");
              } catch (e) {
                alert("Failed to copy script");
              }
            }}
          >
            Copy script
          </button>
        </p>

        <h5>Result</h5>
        <textarea
          rows={10}
          className="w-100"
          onChange={(e) => {
            const text = e.target.value;
            try {
              const scores = JSON.parse(text);
              setScoreData({ scores } as SubmitScoresData);
            } catch (e) {
              console.error(e);
            }
          }}
        ></textarea>
      </div>
      <div className="d-flex" style={{ gap: "4px" }}>
        <button
          className={cx("btn", currentTab === "groups" && "btn-primary")}
          type="submit"
          onClick={() => setCurrentTab("groups")}
        >
          Groups
        </button>
        <button
          className={cx("btn", currentTab === "scores" && "btn-primary")}
          type="submit"
          onClick={() => setCurrentTab("scores")}
        >
          Scores
        </button>
      </div>
      <div className="p-4 border">
        {currentTab === "groups" && (
          <GroupsTable data={groupData} unsaved={unsavedGroups} />
        )}
        {currentTab === "scores" && (
          <ScoresTable data={scoreData} unsavedScores={unsavedScores} />
        )}
      </div>
    </div>
  );
};

export default Update;
