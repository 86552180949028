import React from "react";

import {
  GetGroupsResponse,
  Group,
  SubmitGroupsData,
} from "../../../interfaces/Groups";
import { BaseTable } from "./BaseTable";

export interface GroupsTableProps {
  data?: SubmitGroupsData;
  unsaved?: Group[];
}

export const GroupsTable: React.FC<GroupsTableProps> = ({ data, unsaved }) => {
  if (!data?.groups.length || !unsaved) return <>Loading...</>;

  const renderRow = (group: Group) => (
    <tr key={`${group.name}-${group.class}`}>
      <td>
        {group.url ? (
          <a href={group.url} target="_blank" rel="noopener noreferrer">
            {group.name}
          </a>
        ) : (
          group.name
        )}
      </td>
      <td>{group.location}</td>
      <td>{group.class}</td>
    </tr>
  );
  const tableHeader = (
    <tr>
      <th>Name</th>
      <th>Location</th>
      <th>Class</th>
    </tr>
  );

  return (
    <div>
      <div>
        <strong>Year:</strong> {data.year}
      </div>

      <BaseTable<Group>
        header={`Unsaved: ${unsaved.length}`}
        data={unsaved}
        tableHeader={tableHeader}
        renderRow={renderRow}
      />

      <BaseTable<Group>
        header={`All groups: ${data.groups.length}`}
        data={data.groups}
        tableHeader={tableHeader}
        renderRow={renderRow}
      />
    </div>
  );
};

export default GroupsTable;
