import axios from "axios";
import { Class } from "./interfaces/Class";
import { GetScoresResponse } from "./interfaces/Scores";
import { GetGroupsResponse } from "./interfaces/Groups";

export const formatDate = (date: string) =>
  new Date(date).toLocaleDateString("en-US", {
    year: "2-digit",
    month: "numeric",
    day: "2-digit",
  });

export const formatShow = (show?: string) =>
  show ? show.replace(/- Results/, "") : show;

export const rewriteUrl = (url: string) =>
  process.env.NODE_ENV === "development" ? `http://localhost:4000${url}` : url;

export const getDiff = (currentClass: Class, i: number) => {
  if (!i) {
    return 0;
  }

  const raw =
    parseFloat(currentClass.scores[i - 1].score) -
    parseFloat(currentClass.scores[i].score);

  return Math.round(raw * 100) / 100;
};

const SCORES_URL = rewriteUrl("/api/getScoresV4.php");

export const fetchScores = async (year: string) => {
  const { data } = await axios.get<GetScoresResponse>(SCORES_URL, {
    params: { y: year },
  });
  return data;
};

const GROUPS_URL = rewriteUrl("/api/getGroups.php");

export const fetchGroups = async (year: string) => {
  const { data } = await axios.get<GetGroupsResponse>(GROUPS_URL, {
    params: { y: year },
  });
  return data;
};
