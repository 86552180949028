import React from "react";

export interface YearSelectProps {
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  year: string;
  years: string[];
}

const YearSelect: React.FC<YearSelectProps> = ({ onChange, year, years }) => {
  return (
    <form className="form-inline mr-3">
      <label htmlFor="yearSelect">
        <select
          className="form-control"
          name="year-select"
          id="yearSelect"
          value={year}
          onChange={onChange}
        >
          {years.map((currentYear) => (
            <option key={currentYear} value={currentYear}>
              {currentYear}
            </option>
          ))}
        </select>
      </label>
    </form>
  );
};

export default YearSelect;
