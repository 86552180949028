import React, { useState } from "react";

const MENU_ITEMS = [
  {
    abbreviation: "piw",
    name: "Independent World",
  },
  {
    abbreviation: "psw",
    name: "Scholastic World",
  },
  {
    abbreviation: "pio",
    name: "Independent Open",
  },
  {
    abbreviation: "pso",
    name: "Scholastic Open",
  },
  {
    abbreviation: "pia",
    name: "Independent A",
  },
  {
    abbreviation: "psa",
    name: "Scholastic A",
  },
  {
    abbreviation: "pscw",
    name: "Scholastic Concert World",
  },
  {
    abbreviation: "psco",
    name: "Scholastic Concert Open",
  },
  {
    abbreviation: "psca",
    name: "Scholastic Concert A",
  },
];

export const Header: React.FC = () => {
  const [expanded, setExpanded] = useState(false);

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  return (
    <div className="header" id="header">
      <a
        href="#menu"
        className="menu-icon d-block d-md-none float-right"
        onClick={toggleExpanded}
      >
        &#9776;
      </a>

      <h1 className="main-header">
        <a href="#about">WGI Percussion Rankings</a>
      </h1>
      <ul
        id="nav"
        className={`header-nav d-md-flex justify-content-between${
          expanded ? " expanded" : ""
        }`}
      >
        {MENU_ITEMS.map((item) => (
          <li key={item.abbreviation} className="nav-item">
            <a
              href={`#${item.abbreviation}`}
              title={item.name}
              onClick={toggleExpanded}
            >
              <span className="d-inline d-md-none">{item.name}</span>
              <span className="d-none d-md-inline">
                {item.abbreviation.toUpperCase()}
              </span>
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Header;
